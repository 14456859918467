import Vue from 'vue'
import VueRouter from 'vue-router'
import ReportList from "@/views/ReportList";

Vue.use(VueRouter)

const routes = [
  {
    path: '/vue/list',
    name: 'ReportList',
    component: ReportList
  },
  {
    path: '/vue/report/search',
    name: 'ReportSearch',
    component: () => import(/* webpackChunkName: "about" */ '../views/ReportSearch.vue')
  },
  {
    path: '/vue/report/alert',
    name: 'ReportAlert',
    component: () => import(/* webpackChunkName: "about" */ '../views/ReportAlert.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
