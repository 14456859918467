import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import WeuiVue from 'weui-design-vue'
import 'weui-design-vue/lib/weui-design-vue.css'
Vue.use(WeuiVue)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
